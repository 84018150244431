import React, { Component } from "react";
import ContextProvider from "../components/context/contextProvider";
import HowPage from "../components/how-page/howPage";
import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducer from "../reducers";
import MessageAlert from "../components/message-alert/message-alert";
import Cookies from "../components/cookies/cookies";
import "../styles/shared.scss";
import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/Navbar";
import { navigate } from "gatsby";
import FaqPage from "../components/faq-page/faqPage";

let store;
if (typeof window !== "undefined") {
  store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  store = createStore(allReducer);
}
class How extends Component {
  constructor(props) {
    super(props);
    let logged = false;
    /* if (typeof window !== 'undefined') {
			const session = localStorage.getItem('SeatersEmbeddedAuthToken');
			if (!localStorage.getItem('qvax-selected-region')) {
				navigate('/region');
			}
			if (session) {
				const parsedSession = JSON.parse(session);
				if (this.isSessionValid(parsedSession)) {
					logged = true;
				} else {
					localStorage.removeItem('SeatersEmbeddedAuthToken');
					navigate('/login');
				}
			}
		} */
    this.state = {
      logged,
    };
  }

  render() {
    return (
      <ContextProvider>
        <Provider store={store}>
          <Navbar hideHow={true} hideAuth={true} logout={this.state.logged} />
          <MessageAlert />
          <Cookies />
          <FaqPage
            logged={this.state.logged}
            optinChecked={
              this.props.location.state
                ? this.props.location.state.optinChecked
                : null
            }
            langSaved={
              this.props.location.state ? this.props.location.state.lang : null
            }
          />
          <Footer />
        </Provider>
      </ContextProvider>
    );
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn);
    const dateNow = new Date();
    return expireDate > dateNow;
  }
}

export default How;
