import React from "react";
import Translation from "../translation/translation";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { changeLang } from "../../reducers/actions/index";
import "./faqPage.scss";
import { connect } from "react-redux";
import { DiscFull } from "@material-ui/icons";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';
class FaqPage extends React.Component {
  constructor(props) {
    super(props);

    const faq = [
      // { title: "faq_title_1-1", text: "faq_text_1-1", expanded: false },
      // { title: "faq_title_1", text: "faq_text_1", expanded: false },
      { title: "faq_title_2", text: "faq_text_2", expanded: false },
      { title: "faq_title_3", text: "faq_text_3", expanded: false },
      { title: "faq_title_4", text: "faq_text_4", expanded: false },
      { title: "faq_title_4b", text: "faq_text_4b", expanded: false },
      { title: "faq_title_1-1", text: "faq_text_1-1", expanded: false },
      { title: "faq_title_5", text: "faq_text_5", expanded: false },
      { title: "faq_title_6", text: "faq_text_6", expanded: false },
      // { title: "faq_title_7", text: "faq_text_7", expanded: false },
      { title: "faq_title_8", text: "faq_text_8", expanded: false },
      { title: "faq_title_9", text: "faq_text_9", expanded: false },
      { title: "faq_title_10", text: "faq_text_10", expanded: false },
      { title: "faq_title_11", text: "faq_text_11", expanded: false },
      { title: "faq_title_12", text: "faq_text_12", expanded: false },
      { title: "faq_title_13", text: "faq_text_13", expanded: false },
      // { title: "faq_title_14", text: "faq_text_14", expanded: false }, 
    ];
    this.state = {
      faq,
      lk: DiscFull,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.langSaved) {
      this.props.changeLang(this.props.langSaved);
    }
  }

  render() {
    console.log(this.state.faq);
    return (
      <main>
        <div className="titles">
          <h1>FAQ</h1>
        </div>
        <div className="questions">
          {this.state.faq
            .filter((x, i) => {
              if (this.props.lang === "en") {
                return i < 13;
              }
              if (this.props.lang === "nl") {
                return i < 13;
              }
              if (this.props.lang === "de") {
                return i < 12;
              }
              if (this.props.lang === "fr") {
                return i < 13;
              }
              return true;
            })
            .map((question, index) => (
              <div key={index} className="question">
                <div className="title">
                  <Translation trl={question.title} />
                  {!question.expanded && (
                    <ExpandMoreIcon
                      onClick={() => this.toggleQuestion(index)}
                    />
                  )}
                  {question.expanded && (
                    <ExpandLessIcon
                      onClick={() => this.toggleQuestion(index)}
                    />
                  )}
                </div>

                {question.expanded && (
                  <div className="text">
                    <Translation trl={question.text} />
                  </div>
                )}

                {question.expanded &&
                  (this.props.lang === "nl" || this.props.lang === "en") &&
                  question.title === "faq_title_1" && (
                    <div className="text">
                      <Translation trl={"faq_text_1_a"} />
                      <a
                        href="https://www.laatjevaccineren.be/12-15-jarigen"
                        target="_blank"
                        style={{
                          color: "#236ed3",
                          textDecoration: "underline",
                        }}
                      >
                        <Translation trl={"here_link_2"} />
                      </a>
                      <Translation trl={"faq_text_1_b"} />
                    </div>
                  )}

                {question.expanded &&
                  this.props.lang === "fr" &&
                  question.title === "faq_title_1" && (
                    <div className="text">
                      <Translation trl={"faq_text_1_a"} />
                      <a
                        href="https://www.laatjevaccineren.be/12-15-jarigen"
                        target="_blank"
                        style={{
                          color: "#236ed3",
                          textDecoration: "underline",
                        }}
                      >
                        <Translation trl={"here_link_2"} />
                      </a>
                      <Translation trl={"faq_text_1_b"} />
                    </div>
                  )}

                  {question.expanded &&
                  this.props.lang === "nl" &&
                  question.title === "faq_title_13" && (
                    <div className="text-custom">
                      <Translation trl={"faq_text_13a"} />
                      <a
                        href="https://www.laatjevaccineren.be/covid-19-vaccinatie/vaccinatiecentra-in-vlaanderen-waar-krijg-ik-mijn-vaccin"
                        target="_blank"
                        style={{
                          color: "#236ed3",
                          textDecoration: "underline",
                        }}
                      >
                        <Translation trl={"faq_text_13a_link"} />
                      </a><br></br>
                      <Translation trl={"faq_text_13b"} /><br></br>
                      • Algemene informatie over COVID-19-vaccinatie vindt u terug op de website{' '}
                      <a
                        href="https://www.laatjevaccineren.be/"
                        target="_blank"
                        style={{
                          color: "#236ed3",
                          textDecoration: "underline",
                        }}
                      >
                        https://www.laatjevaccineren.be/ 
                      </a>{' '}(zoek daar zeker ook in de{' '}
                        <a
                        href="https://www.laatjevaccineren.be/covid-19-vaccinatie/vaccinatie-tegen-covid-19-vraag-en-antwoord"
                        target="_blank"
                        style={{
                          color: "#236ed3",
                          textDecoration: "underline",
                        }}
                      >
                        veelgestelde vragen
                      </a>); via het contactformulier kan u ook specifieke vragen stellen waarop u het antwoord niet meteen vindt{' '}
                      <a
                        href="https://www.laatjevaccineren.be/contacteer-ons"
                        target="_blank"
                        style={{
                          color: "#236ed3",
                          textDecoration: "underline",
                        }}
                      >
                        Contact (laatjevaccineren.be)
                      </a>.
                    </div>
                  )}
                  {question.expanded &&
                  this.props.lang === "en" &&
                  question.title === "faq_title_13" && (
                    <div className="text-custom">
                      <Translation trl={"faq_text_13a"} />
                      <a
                        href="https://www.laatjevaccineren.be/covid-19-vaccinatie/vaccinatiecentra-in-vlaanderen-waar-krijg-ik-mijn-vaccin"
                        target="_blank"
                        style={{
                          color: "#236ed3",
                          textDecoration: "underline",
                        }}
                      >
                        <Translation trl={"faq_text_13a_link"} />
                      </a><br></br>
                      <Translation trl={"faq_text_13b"} /><br></br>
                      • General information about COVID-19 vaccination can be found on the website {' '}
                      <a
                        href="https://www.laatjevaccineren.be/"
                        target="_blank"
                        style={{
                          color: "#236ed3",
                          textDecoration: "underline",
                        }}
                      >
                        https://www.laatjevaccineren.be/
                      </a>{' '}(be sure to search the frequently asked questions{' '}
                        <a
                        href="https://www.laatjevaccineren.be/veelgestelde-vragen"
                        target="_blank"
                        style={{
                          color: "#236ed3",
                          textDecoration: "underline",
                        }}
                      >
                        there
                      </a>; via the contact form you can also ask specific questions to which you cannot immediately find the answers: {' '}
                      <a
                        href="https://www.laatjevaccineren.be/contacteer-ons"
                        target="_blank"
                        style={{
                          color: "#236ed3",
                          textDecoration: "underline",
                        }}
                      >
                        Contact (laatjevaccineren.be)
                      </a>.
                    </div>
                  )}



              </div>
            ))}
        </div>
      </main>
    );
  }

  toggleQuestion(index) {
    const questions = this.state.faq;
    questions[index].expanded = !questions[index].expanded;
    this.setState({ faq: questions });
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
});

const mapDispatchToProps = () => {
  return {
    changeLang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(FaqPage);
